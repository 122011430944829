.top-bar-contaienr {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 3px 8px;
  border-radius: 0 0 8px 8px;
  font-size: 12px;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.change-zone-container {
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
}

.zones-menu {
  position: absolute;
  background-color: rgba(25, 25, 25, 0.9);
  color: white;
  top: 22px;
  left: 5px;

  .item {
    padding: 4px 8px;
    border-bottom: 1px solid grey;
    cursor: pointer;
  }

  .item:hover {
    background-color: rgba(75, 75, 75, 0.9);
  }
}
