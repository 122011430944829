html,
body {
  overflow: hidden !important;
}

.App {
  position: relative !important;
}

.rightMenuTrigger {
  position: absolute;
  top: 5px;
  right: 5px;

  svg {
    font-size: 2em;
    cursor: pointer;
  }
}

.leftMenuTrigger {
  z-index: 5;
  position: absolute;
  top: 5px;
  left: 5px;

  svg {
    font-size: 2em;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  canvas {
    width: 100vw !important;
    height: 100vh !important;
    overflow: hidden !important;
  }
}

.own-stats {
  top: unset !important;
  bottom: 0 !important;
  right: 0 !important;
  left: unset !important;
}
