.set-address-modal-container {
  background-color: #161616;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 8px 0 0 0;
  padding: 6px;
  z-index: 5000;
  font-size: 10px;

  label {
    margin-right: 10px;
  }
}
