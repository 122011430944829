.left-menu-container {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.90);
    width: 20%;
    height: 100vh;
    transition: left 0.5s ease;
    z-index: 5;

    .top-menu-content {
        svg {
            position: absolute;
            top: 5px; 
            left: 5px;
            font-size: 2em;
            color: white;
            cursor: pointer;
        }
    }
    .modal-content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #eee;
        flex-direction: column;

        .top-menu-content {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 40px;
        }
    }
    .left-menu-main-content {
        width: 100%;
        
        .menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: solid 1px rgba(255, 255, 255, 0.20);
            border-bottom: solid 1px rgba(255, 255, 255, 0.20);
            padding: 8px 0;
            width: 100%;
            cursor: pointer;
    
            path {
                stroke: white;
            }
    
            svg {
                margin: 0 10px;
            }
        }
    }
}
.left-hideMenu {left: -555px;}
.left-showMenu {left: 0;}

@media only screen and (max-width: 600px) {
    .left-menu-container {
        width: 100%;
    }
}