.detail-window-container {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    z-index: 1 !important;
    display: flex;
    font-size: 12px;
    height: 30px;
    border-radius: 8px 8px 0 0;

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px;

        .label {
            font-size: bold;
            margin: 0 10px 0 0;
            text-transform: uppercase;
            text-align: right;
            width: 100%;
            white-space: nowrap;
        }

        input, button {
            background-color: black;
            border-radius: 8px;
            outline: 0;
            box-shadow: 0;
            border: 0;
            height: 15px;
            color: white;
            padding: 4px;
            text-align: center;
            min-width: 50px;
            width: 100%;
            cursor: pointer;
            white-space: nowrap;
        }

        input:disabled {
            background-color: rgba(0,0,0,0.3);
        }

        .input {
            white-space: nowrap;

            .sketch-picker, .combo-box-container {
                position: fixed;
                z-index: 10;
            }

            .combo-box-container {
                background-color: #232323;
                max-height: 200px;
                overflow-y: auto;
                padding: 8px;
                // border-radius: 8px;
                margin: 2px 0;
                width: fit-content;
                transform: translate(30%, 0);
                position: absolute;
                bottom: 27px;
                left: inherit;


                .list-element {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                p {
                    border-bottom: 1px solid #555;
                    margin: 5px 5px;
                    white-space: nowrap;
                    cursor: pointer;
                }

                input {
                    background-color: grey;
                    color: #222;
                    text-align: left;
                    width: 97%;
                    cursor: text;
                }
                input::placeholder {
                    color: #222
                }

                .loader {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                }

            }
        }
    }
    .separator {
        border-right: 1px solid rgba(255, 255, 255, 0.75);
    }
}

@media only screen and (max-width: 600px) {
    .detail-window-container {
        left: 0;
        right: 0;
        top: 0;
        height: 25vh;
        width: 100%;
        overflow: auto;
    }
}