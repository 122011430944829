.image-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px;

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        svg {
            color: black;
            font-size: 20px;
        }
    }

    img {
        width: 100%;
    }
}