.button-style {
  cursor: pointer;
  background-color: grey;
  margin: 0 2px;
  border-radius: 3px;
  outline: 0;
  border: 0;
}

.enable-style {
  background-color: white;
  cursor: pointer;
  margin: 0 2px;
  border-radius: 3px;
  outline: 0;
  border: 0;
}

.item-name {
  margin-right: 12px;
}
