.right-advanced-menu {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  width: 25vw;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  right: -25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 8px;
  }
}

.active-right-advanced-menu {
  right: 0;
}

.right-advanced-menu h5 {
  color: #eee;
  padding: 0 1rem;
}

.item-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  transition: all 0.5s ease-in-out;
  margin-left: 0;
}

.menu-item {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.item-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

hr {
  border-color: #444;
  margin: 0;
}

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.page-item {
  cursor: pointer;
}

.item-content {
  padding: 1rem 2rem;
  background-color: #222;
}

.right-advanced-menu .item-content select {
  width: 100%;
  background-color: #222;
  color: white;
  border: 1px solid #000;
  padding: 8px;
}

.right-advanced-menu .item-content input {
  width: 94%;
  background-color: #222;
  color: white;
  border: 1px solid #000;
  padding: 8px;
}

.right-advanced-menu .item-content option {
  padding: 8px;
}
