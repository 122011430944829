.context-menu-item {
  display: flex;
  border-bottom: 1px solid grey;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  svg {
    font-size: 20px;
    margin-right: 8px;
    padding: 0;
  }

  * {
    white-space: nowrap;
  }
}

.item-name {
  flex: auto;
}

.context-menu-item:hover {
  background-color: #555;
}

.item-disabled {
  background-color: #555;
  color: grey;
}
