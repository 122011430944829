.help-modal {
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 1rem;
  z-index: 1000;
  border-radius: 8px;
}

.help-modal h3 {
  text-transform: uppercase;
  margin: 0 0 0.8rem 0;
  padding: 0;
}

.help-modal hr {
  border-color: #999;
}

.help-modal-content {
  display: flex;
}

.help-modal-content table {
  width: 100%;
}

.help-modal-content table td {
  padding: 0.5rem;
  white-space: nowrap;
}

.help-modal-content table td strong {
  color: yellow;
}

.help-modal div {
  flex: 1;
}
