.top-menu-container {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.90);
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    color: white;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    border-radius: 8px 0 0 8px;

    .item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding: 2px 4px;
        cursor: pointer !important;
    }

    .separator {
        border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    }

    svg {
        padding: 0;
        margin: 0;
        font-size: 22px;
    }

    .disabled {
        color: rgba(255,255,255,0.4);
    }

    .disabled:hover {
        color: unset !important;
    }
}