.change-address-modal-container {
  background-color: #333333;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 10px;
  z-index: 5000;

  label {
    margin-right: 10px;
  }
}
