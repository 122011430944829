.split-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333333;
  color: #eee;
  z-index: 500;
  display: flex;
  align-items: center;

  .split-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translate(50%, -50%);
    background-color: #222;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 600;
  }

  .add-icon {
    cursor: pointer;
    text-align: end;
    height: 100%;
    padding: 0 8px;
  }

  .item-row {
    display: flex;
  }

  .split-modal-content {
    padding: 16px 16px 16px 0;
  }

  button {
    background-color: white;
    border: 0;
    outline: 0;
    padding: 6px;
    margin-right: 8px;
    border-radius: 4px;
  }
}
