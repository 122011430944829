.loader-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  z-index: 500000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
