.filter-modal {
  background-color: #333333;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 10px;
  max-width: 25vw;
  z-index: 5000;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: #777;
    border-radius: 50px;
    padding: 3px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .buttonDisabled {
    background-color: #888;
    color: #eeeeee;
    outline: 0;
    border: 0;
  }

  .buttonEnabled {
    background-color: white;
    color: #333333;
  }
  .mobileCalendar {
    display: none;
  }

  .buttonDefault {
    cursor: pointer;
    margin: 0 3px;
    padding: 6px;
  }

  .buttonDefault:hover {
    background-color: white;
    color: #333333;
  }

  h6 {
    margin: 0;
  }
  /* Range Slider as a Single Thumb Slider */
  .single-thumb .range-slider__thumb[data-lower] {
    width: 0;
  }
  .single-thumb .range-slider__range {
    border-radius: 6px;
  }
}

@media only screen and (max-width: 768px) {
  .desktopCalendar {
    display: none;
  }

  .mobileCalendar {
    display: block !important;
  }

  .filter-modal {
    max-width: 90vw;
  }
}
