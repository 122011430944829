h5 {
  margin: 10px 0;
}
.children {
  overflow: hidden;
  width: 100% !important;
  transition: all 0.5s;
  visibility: none;
  max-height: 0;
}

.visible {
  max-height: 2000px;
  visibility: visible;
}

.visible:hover {
  background-color: #333 !important;
}

.hidden {
  max-height: 0px;
  visibility: hidden;
}
