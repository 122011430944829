.item3D {
  background-color: black;
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1000;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 6px;
  border-radius: 50px;
  cursor: pointer;

  svg {
    font-size: 20px;
  }
}

.active-3d {
  color: white !important;
}

.disabled {
  color: #999999 !important;
}
