.add-new-item-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333333;
  color: #eee;
  padding: 16px;
  display: flex;

  .list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 400px;
  }

  .item {
    cursor: pointer;
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    display: inline-block;
    max-width: 45%;
    width: 100%;
    margin: 0 8px;
  }

  .close-modal-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translate(50%, -50%);
    background-color: #222;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
