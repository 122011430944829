.scale-modal-container {
  background-color: #333333;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 10px;
  z-index: 5000;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: #777;
    border-radius: 50px;
    padding: 3px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  label {
    margin-right: 10px;
  }
}
