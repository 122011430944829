.rotate-elements-modal {
  background-color: #333333;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 10px;
  max-width: 25vw;
  z-index: 5000;

  input {
    width: 50px;
  }
}
