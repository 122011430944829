.menu-container {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.90);
    width: 20%;
    height: 100vh;
    transition: all 0.5s ease;
    z-index: 50;

    svg {
        position: absolute;
        top: 5px; 
        right: 5px;
        font-size: 2em;
        color: white;
        cursor: pointer;
    }
    .modal-content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #eee;
        flex-direction: column;

        .top-menu-content {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
}
.hideMenu {right: -555px;}
.showMenu {right: 0;}

@media only screen and (max-width: 600px) {
    .menu-container {
        width: 100%;
        height: 100vh !important;
        overflow: hidden !important;
    }
}